.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 1vmin);
  color: white;
}

.App-heading {
  color: #ffbfa1;
}


.hr {
  padding: 10px 20px; 
}

.Headline-Hauptmann {
  color: #ab0000;
}

.App2-Button {
  font-size: 1.5em; 
  padding: 10px 20px; 
  border-radius: 50px; 
  border: none; 
  background-color: #ffbfa1; 
  color: white; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
}

.App2-Button:hover {
  background-color: #ab0000; 
}
