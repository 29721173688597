.table-holder {
  margin-left: 20px;
  margin-right: 20px;
}
table {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 3 px solid red;
  padding: 20px;
}
td {
  padding: 4px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
